// Represents search results page inside infinitely scrolled stream of pages.
// This search results page takes care of any user interaction and events.
export default class SearchResultsPage {
  constructor({$container, scrolled}) {
    this.scrolled = scrolled;
    this.$container = $container;
    this.lids = this.getListingIds();
  }

  getListingIds() {
    return this.$container.find("[data-role=show-listing]").toArray().reduce((acc, i) => {
      let id = parseInt($(i).data("id"));
      if(acc.indexOf(id) === -1) { acc.push(id); }
      return acc;
    }, []);
  }

  triggerViewEvent() {
    let eventData = {
      lids: this.lids,
      scrolled: this.scrolled
    }
    let $event = $.Event("machinio.search_results_page.view", eventData);
    $(window).trigger($event);
  }
}
