export default class InfiniteScroll {
  constructor(searchResults) {
    this.didScroll = false;
    this.lastScroll = 0;
    this.searchResults = searchResults;
    this.deepestPageViewed = 1;
  }

  initialize(){
    this.pageNumber = this.getPageNumberFromPath(window.location.search);
    this.showPageIndicator = false;

    if ($('link[rel=next]').length) {
      this.nextPageDataUrl = $('link[rel=next]').attr('href');
    }

    if ($('#search-results').length) {
      this.$listingContainer = $('#search-results');
    } else if ($('#content').length) {
      this.$listingContainer = $('#content');
    } else {
      this.$listingContainer = '';
    }

    if ($(document).height() <= $(window).height()) {
      this.showPageIndicator = true;
      this.loadNext();
    }

    $(window).on("scroll", e => {
      this.didScroll = true;
    });

    setInterval(()=> {
      if(this.didScroll) {
        this.didScroll = false;
        this.handleScrolling();
      }
    }, 100);
  }

  handleScrolling() {
    let scrollPos = $(window).scrollTop();
    if (scrollPos >= 0.8 * ($(document).height() - $(window).height())) {
      if (!this.isLoading){
        this.loadNext();
      }
    }
    // throttling, to avoid handling scroll too often
    if (Math.abs(scrollPos - this.lastScroll) > $(window).height() * 0.1) {
      this.lastScroll = scrollPos;
      $(".offer-listing__list").each((i, ul) => {
        if(this.isMostlyVisible(ul)) {
          if (window.history && history.replaceState) {
            let newPath = $(ul).attr("data-path");
            let currentPath = `${location.pathname}${location.search}`

            if (newPath && newPath !== currentPath) {
              let pageNumber = this.getPageNumberFromPath(newPath);

              history.replaceState(null, null, newPath);
              if (pageNumber > this.deepestPageViewed) {
                this.deepestPageViewed = pageNumber;
                this.searchResults.pages[i].triggerViewEvent();
              }
            }
          }
          return false;
        }
      });
    }
  }

  loadNext(){
    this.isLoading = true;
    if(this.nextPageDataUrl) {
      this.showLoadingText();
      $.getJSON(this.nextPageDataUrl, {format: 'json'}, data => {
        this.hideLoadingText();
        this.showNext(data);
        this.isLoading = false;
      });
    }
  }

  showNext(response) {
    let currentPageCapture = '';

    if (this.$listingContainer.length) {
      this.$listingContainer.append(response.html);
    }

    this.$lastSearchResult = $('.search-results-page:last');

    this.setNextPageUrl(response.next_page_data_url);

    if (this.nextPageDataUrl.length) {
      currentPageCapture = this.nextPageDataUrl.match(/page=(\d+)/);
    }

    if (currentPageCapture.length) {
      this.currentPage = parseInt(currentPageCapture[1]);
    } else {
      this.currentPage = 1;
    }

    this.maybeAppendPageIndicator(this.suggestedSearch);
    this.searchResults.addPage({$container: $(".search-results-page:last"), scrolled: true});
  }

  setNextPageUrl(nextPageUrl) {
    if (nextPageUrl) {
      this.nextPageDataUrl = nextPageUrl;
    } else {
      this.nextPageDataUrl = '';
    }
  }

  showLoadingText() {
    if (this.$listingContainer.length) {
      this.$listingContainer.append(`<h5 id='is-loader' class='text-center topm-10 listing__loading-text'>Loading...<br/></h5>`);
    }
  }

  hideLoadingText() {
    $("#is-loader").remove();
  }

  maybeAppendPageIndicator(suggestedSearch) {
    if (this.showPageIndicator) {
      let $pageIndicator = $(document.createElement('div')).addClass('suggested-page-indicator row'),
        $pageIndicatorLine = $(document.createElement('span')).addClass('suggested-page-indicator--line col-sm-3 hidden-xs'),
        $pageIndicatorCopy = $(document.createElement('span')).addClass('suggested-page-indicator--copy col-sm-6 col-xs-12'),
        $pageIndicatorSearchCopy = $(document.createElement('span')).addClass('suggested-page-indicator--search-copy').text('Search Results for'),
        $pageIndicatorSearchKeywords = $(document.createElement('strong'));

      $pageIndicatorCopy.append($pageIndicatorSearchCopy);
      $pageIndicatorSearchKeywords.text(this.suggestedSearch);

      $pageIndicatorCopy.append($pageIndicatorSearchKeywords);
      $pageIndicator.append($pageIndicatorLine);
      $pageIndicator.append($pageIndicatorCopy);
      $pageIndicator.append($pageIndicatorLine.clone());

      $pageIndicator.insertAfter(this.$lastSearchResult);

      this.showPageIndicator = false;
    }
  }

  isMostlyVisible(element) {
    let scrollPos = $(window).scrollTop();
    let windowHeight = $(window).height();
    let elTop = $(element).offset().top;
    let elHeight = $(element).height();
    let elBottom = elTop + elHeight;
    return (elBottom - elHeight * 0.25 > scrollPos) && (elTop < (scrollPos + 0.5 * windowHeight));
  }

  getPageNumberFromPath(path) {
    let matches = path.match(/[&|\?]page=(\d{1,2})/);
    let page = matches ? matches[1] : 1;

    return Number(page);
  }
}
