import typeahead from 'corejs-typeahead';
import Bloodhound from 'bloodhound-js'

export default class LocationAutocomplete {
  constructor() {
    this.$field = $('.typeahead-location');
    this.$prefetchUrl = '/dictionaries/locations.json';
    this.init();
  }

  init() {
    this.bindAutocomplete();
  }

  bindAutocomplete() {
    this.$field.typeahead({
      hint: false,
      highlight: true,
      minLength: 1
    }, {
      displayKey: 'name',
      source: this.source()
    });
  }

  source() {
    const keys = ['name', 'tag'];

    var bloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace.apply(this, keys),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      prefetch: { url: this.$prefetchUrl }
    });

    bloodhound.initialize();

    return bloodhound.ttAdapter();
  }
}
