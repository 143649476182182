import InfiniteScroll from './InfiniteScroll';
import SearchResultsPage from './SearchResultsPage';
import { BeaconAgent } from "beacon-agent";
export default class SearchResults {
  constructor() {
    this.infscroll = new InfiniteScroll(this);
    this.pages = [];
    this.init();
  }

  init() {
    this.infscroll.initialize();
    // need to add first page of results
    this.addPage({$container: $(".search-results-page:last"), scrolled: false});
    this.pages[0].triggerViewEvent();
    this.bindEvents();
    this.trackPageView();
  }

  bindEvents() {
    $(window).on("machinio.search_results_page.view", (e) => {
      this.trackPageViewInGA(e);
      this.trackPageView(e);
    });  
  }

  addPage({$container, scrolled}) {
    let page = new SearchResultsPage({ $container: $container, scrolled: scrolled });
    this.pages.push(page);
  }

  trackPageViewInGA(pageEvent) {
    if (pageEvent.scrolled) {
      try {
        let pathAndSearch = location.pathname + location.search;
        ga("set", "page", pathAndSearch);
      } catch (e) { /* squelch */ }
      ga("send", "pageview");
    }
  }

  trackPageView(pageEvent) {
    let tracker = new BeaconAgent("https://eva.machiniotools.com/api");
    tracker.send({eventType: "pageview"});
  }
}
