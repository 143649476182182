export default class SearchBox {
  constructor() {
    this.$search = $('#search');
    this.$mobileSearch = $('#mobile-search');
    this.$where = $('#where');
    this.$upperSearchForm = $('#upper-search-form');
    this.$mobileSearchForm = $('#mobile-search-box .search-form');
    this.$upperFloatingTip = $('#upper-floating-tip');
    this.$skipToSearch = $('#skip-to-search');

    this.init();
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    this.$skipToSearch.on('click', () => $search.focus());

    this.$upperSearchForm.on('submit', (e, options) => {
      this.submitSearch(e, options);
    });

    this.$search.on('focusin keydown', () => {
      this.maybeRemoveHint();
    });

    this.$mobileSearchForm.on('submit', (e, options) => {
      options = options || {};
      options.mobile = true;
      this.submitSearch(e, options);
    });
  }

  maybeRemoveHint() {
    if (!this.$upperFloatingTip.hasClass('hidden')) {
      this.$upperFloatingTip.addClass('hidden');
    }
  }

  submitSearch(e, options={}) {
    if (this.$search.val() === '' && !options.mobile || options.mobile && this.$mobileSearch.val() === '') {
        e.preventDefault();
        return this.$upperFloatingTip.removeClass('hidden');
    }
  }
}
