export default class StickyNav {
  constructor() {
    this.lastScrollTop = $(window).scrollTop();

    this.$stickyNav = $('#sticky-header');
    
    this.stickyNavHeight = this.$stickyNav.outerHeight();
    this.$navLogo = this.$stickyNav.find('.header__logo');
    this.logoHeight = this.$navLogo.outerHeight();

    this.topLogoThreshold = 250;
    this.mobileBreakpoint = 768;
    this.isMobile = $(window).width() >= this.mobileBreakpoint;
    this.startStickyNavThreshold = this.isMobile ? 0 : $('#nav').outerHeight();

    this.$megaMenu = $('#categories-mega-menu');
    this.megaMenuTop = 0;

    this.hideLogo = this.testNavHeight();

    this.bindEvents();
  }

  bindEvents() {
    this.scrollLoop();
  }

  

  handleStickyNav() {
    const scrollTop = $(window).scrollTop();
    const deltaScroll = this.lastScrollTop - scrollTop;

    this.lastScrollTop = scrollTop;

    if (deltaScroll === 0) return;

    this.maybeHideDesktopLogo(scrollTop, this.topLogoThreshold);

    if (scrollTop < this.startStickyNavThreshold) return;

    this.setNavPosition(deltaScroll, scrollTop);
  }

  maybeHideDesktopLogo(scrollTop) {
    if (!this.isMobile && this.hideLogo) {
      const range = 1;
      if (scrollTop > this.topLogoThreshold + range) {
        this.$navLogo.hide();
      } else if (scrollTop < this.topLogoThreshold - range) {
        this.$navLogo.show();
      }
    }
  }

  setNavPosition(deltaScroll, scrollTop) {
    const navTopPos = this.getNavMinMaxPos(scrollTop);

    let currentTopPos = this.$stickyNav.data('top') || 0,
      nextTopPos = deltaScroll + currentTopPos;

    if (nextTopPos >= navTopPos.max) {
      nextTopPos = navTopPos.max;
    } else if (nextTopPos < navTopPos.min) {
      nextTopPos = navTopPos.min;
    }

    this.$stickyNav.data('top', nextTopPos);
    this.$stickyNav.css({
      '-webkit-transform': `translate3d(0,${nextTopPos}px,0)`,
      'transform': `translate3d(0,${nextTopPos}px,0)`
    });
  }

  getNavMinMaxPos(scrollTop) {
    const accountForLogo = !this.hideLogo && scrollTop > this.topLogoThreshold;
    let navMaxTopPos,
      navMinTopPos;

    if (accountForLogo) {
      navMaxTopPos = -this.logoHeight;
      navMinTopPos = -this.logoHeight - this.stickyNavHeight;
    } else {
      navMaxTopPos = 0;
      navMinTopPos = -this.stickyNavHeight;
    }

    return {
      min: navMinTopPos,
      max: navMaxTopPos
    };
  }

  scrollLoop() {
    const frameFunction = window.requestAnimationFrame ||
                          window.webkitRequestAnimationFrame ||
                          window.mozRequestAnimationFrame ||
                          window.msRequestAnimationFrame ||
                          window.oRequestAnimationFrame;

    const loop = () => {
      this.handleStickyNav();
      frameFunction(loop);
    }

    loop();
  }

  testNavHeight() {
    const beforeNavHeight = this.$stickyNav.outerHeight();
    this.$navLogo.hide();
    const afterNavHeight = this.$stickyNav.outerHeight();
    this.$navLogo.show();
    return Math.abs(beforeNavHeight - afterNavHeight) < 5;
  }
}
